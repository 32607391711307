<template>
    <div class="wrapper -quienes-somos padding-menu -fondo-campo">
        <div class="breadcrumb">
            <div class="container row-end">
                <router-link to="/">Grupo IAN</router-link>
                <router-link to="/" style="pointer-events: none;">{{ $t('navbar_menu.group_ian.title') }}</router-link>
                <router-link :to="$route.path">{{ $t('about_us.title') }}</router-link>
            </div>
        </div>

        <section class="intro-page">
            <div class="container">
                <h1>{{ $t('about_us.title') }}</h1>
                <img :src="$t('about_us.main_banner.img')" alt="img main banner" class="hidde-mobile">
                <img :src="$t('about_us.main_banner.imgMobile')" alt="img main banner" class="hidde-destop">
                <div class="green-box">
                    <h2>{{ $t('about_us.main_banner.title') }}</h2>
                    <div v-html="$t('about_us.main_banner.text')"></div>
                </div>
            </div>
        </section>

        <section class="banner-sobre-nosotros">
            <div class="container row-end">
                <img :src="$t('about_us.groupIAN_banner.img')" alt="Sobre nosotros">
                <div class="texto">
                    <h2>{{ $t('about_us.groupIAN_banner.title') }}</h2>
                    <div v-html="$t('about_us.groupIAN_banner.text')"></div>
                </div>
            </div>
        </section>

        <section class="banner-historia">
            <div class="container row-start">
                <div class="texto">
                    <h2 v-html="$t('about_us.history_banner.title')"></h2>
                    <div v-html="$t('about_us.history_banner.text')"></div>
                </div>
                <img :src="$t('about_us.history_banner.img1')" alt="Quiénes somos" class="quienes-somos-1">
            </div>
            <img :src="$t('about_us.history_banner.img2')" alt="Quiénes somos" class="quienes-somos-2">
        </section>

        <section class="banner-mercados">
            <div class="container row-start">
                <div class="contenedor-imagen -gran-consumo">
                    <img :src="$t('about_us.markets_banner.large_consumption.img')" alt="Mercados" class="mercado-1">
                    <h2>{{ $t('about_us.markets_banner.large_consumption.title') }}</h2>
                    <div class="texto-imagen" v-html="$t('about_us.markets_banner.large_consumption.text')"></div>
                </div>
                <div class="texto">
                    <h2>{{ $t('about_us.markets_banner.title') }}</h2>
                    <div v-html="$t('about_us.markets_banner.text')"></div>
                </div>
                <div class="contenedor-imagen -distribucion">
                    <img :src="$t('about_us.markets_banner.distribution_entities.img')" alt="Mercados">
                    <h2>{{ $t('about_us.markets_banner.distribution_entities.title') }}</h2>
                    <div class="texto-imagen" v-html="$t('about_us.markets_banner.distribution_entities.text')"></div>
                </div>
                <div class="contenedor-imagen -foodservice">
                    <img :src="$t('about_us.markets_banner.food_service.img')" alt="Mercados">
                    <h2>{{ $t('about_us.markets_banner.food_service.title') }}</h2>
                    <div class="texto-imagen" v-html="$t('about_us.markets_banner.food_service.text')"></div>
                </div>
                <div class="contenedor-imagen -venta">
                    <img :src="$t('about_us.markets_banner.industrial_sales.img')" alt="Mercados">
                    <h2>{{ $t('about_us.markets_banner.industrial_sales.title') }}</h2>
                    <div class="texto-imagen" v-html="$t('about_us.markets_banner.industrial_sales.text')"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'quienes-somos',
}
</script>

<style lang="scss" scoped>

@media screen and (max-width:1040px) {
    .intro-page img {
        width: 100%;
    }
}

.intro-page .green-box {
    max-width: 500px;
}
</style>